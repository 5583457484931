import { Injectable } from '@angular/core';
import {includes} from 'lodash';

@Injectable()
export class ABTestService {
    private flags: Array<string>;

    constructor() {
        this.flags = [];
    }

    public setFlags (flags: Array<string>) {
        this.flags = flags.filter((f) => f.startsWith('ab_'));
    }

    public hasTestFlag(testName) {
        return includes(this.flags, `ab_${testName}`);
    }
}
