import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthLoginComponent} from 'app/pages/auth/login/login.component';
import {AuthLoginModule} from 'app/pages/auth/login/login.module';

import {AuthPasswordComponent} from "app/pages/auth/password/password.component";
import {AuthPasswordModule} from "app/pages/auth/password/password.module";

import {FileReportComponent} from 'app/pages/file-report/file-report.component';
import {FileReportModule} from 'app/pages/file-report/file-report.module';

import {HomeComponent} from 'app/pages/home/home.component';
import {HomeModule} from 'app/pages/home/home.module';

import {AuthenticationGuard} from 'app/common/guards/authentication.guard';
import {GlobalServiceDataResolver} from 'app/common/resolvers/GlobalServiceDataResolver';

import {TemplateDefaultComponent} from 'app/common/template-default/template-default.component';
import {TemplateLoginComponent} from 'app/common/template-login/template-login.component';
import {TemplateFileReportComponent} from 'app/common/template-file-report/template-file-report.component';

const routes: Routes = [
    {
        path: '',
        component: TemplateDefaultComponent,
        resolve: {
            global: GlobalServiceDataResolver
        },
        canActivateChild: [AuthenticationGuard],
        children: [
            {path: '', component: HomeComponent, pathMatch: 'full', data: {title: 'Home'}},
            {path: '', loadChildren: './pages/other/other.module#OtherModule'}, // TODO this needs to have own path
            {path: 'black-hat', loadChildren: './pages/black-hat/black-hat.module#BlackHatModule'},
            {path: 'labeler', loadChildren: './pages/labeler/labeler.module#LabelerModule'},
            {path: 'settings', loadChildren: './pages/settings/settings.module#SettingsModule'},
            {path: 'setup', loadChildren: './pages/setup/setup.module#SetupModule'},
            {path: 'shelf-cams', loadChildren: './pages/shelf-cams/shelf-cams.module#ShelfCamsModule'},
        ]
    },
    {
        path: 'auth',
        component: TemplateLoginComponent,
        canActivateChild: [AuthenticationGuard],
        children: [
            {path: '', redirectTo: 'login', pathMatch: 'full'},
            {path: 'login', component: AuthLoginComponent, data: {title: 'Login'}},
            {path: 'password', component: AuthPasswordComponent, data: {title: 'Password reset'}},
        ]
    },
    {
        path: 'file-report',
        component: TemplateFileReportComponent,
        canActivateChild: [AuthenticationGuard],
        children: [
            {path: '', redirectTo: 'file-report', pathMatch: 'full'},
            {path: 'file-report', component: FileReportComponent, data: {title: 'File Report'}},
        ]
    },
    {
        path: '**', redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        AuthLoginModule,
        AuthPasswordModule,
        HomeModule,
        RouterModule,
        FileReportModule,
    ],
    providers: [
        AuthenticationGuard,
        GlobalServiceDataResolver
    ]
})
export class AppRoutingModule {
}
