import {OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {StoreFilter} from 'app/common/classes/StoreFilter';
import {GlobalService} from 'app/common/services/global/global.service';

export abstract class GlobalStoreFilterAwareComponent implements OnInit, OnDestroy {

    storeFilter: StoreFilter;
    storeFilterSubscription: Subscription;

    protected constructor(
        protected $global: GlobalService
    ) {
    }

    ngOnInit(): void {
        this.storeFilterSubscription = this.$global.storeFilter$.subscribe(this.onStoreFilterUpdate.bind(this));
    }

    ngOnDestroy(): void {
        this.storeFilterSubscription.unsubscribe();
    }

    onStoreFilterUpdate(newStoreFilter: StoreFilter) {
        if (newStoreFilter) {
            this.storeFilter = newStoreFilter;
        }
    }

}
