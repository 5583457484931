export class StoreFilter {

    constructor(
        public readonly chain: string,
        public readonly storeName: string,
        public readonly storeLegibleName?: string
    ) { }

    get storeDisplayName () {
        return this.storeLegibleName ? this.storeLegibleName : this.storeName;
    }

    isEmpty() {
        return !this.chain && !this.storeName;
    }
    
}
