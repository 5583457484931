import * as _ from 'lodash';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';

import {NavigationItem, NavigationLink} from 'app/common/interfaces/NavigationItem';
import {GlobalService} from 'app/common/services/global/global.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [
        trigger('bodyExpansion', [
            state('collapsed', style({height: '0px', display: 'none'})),
            state('expanded', style({height: '*', display: 'block'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
        ]),
    ],
})
export class NavigationComponent implements OnInit {

    items: Array<NavigationItem>;
    collapsedSections: Array<string>;

    constructor(
        private global: GlobalService
    ) {
        this.items = [];
        this.collapsedSections = [];
    }

    private static unionChildPermissions(children: Array<NavigationLink>): Array<string> {
        let permissions: Array<string> = [];
        for (const child of children) {
            permissions = _.union(permissions, child.permissions || []);
        }
        return permissions;
    }

    ngOnInit(): void {
        const {items, collapsedSections} = this.global.getNavigationConfig();

        this.items = items;
        this.collapsedSections = collapsedSections;

        this.items.forEach((entry: NavigationItem) => {
            if ('children' in entry) {
                entry.permissions = NavigationComponent.unionChildPermissions(entry.children);
            }
        });
    }

    storeToggleState(section: string, newState: boolean) {
        this.collapsedSections = newState
            ? _.uniq([...this.collapsedSections, section])
            : _.without(this.collapsedSections, section);
        this.global.setNavigationCollapsedSections(this.collapsedSections);
    }

    isCollapsed(section: string): boolean {
        return _.includes(this.collapsedSections, section);
    }

}
